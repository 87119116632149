<template>
    <div id="EventStatusPlanningGroup">
        <v-container fluid app>
            <v-card>
                <v-row no-gutters class="d-flex align-center justify-start mx-2 pt-0">
                    <v-col class="d-flex justify-start ml-2">
                        <div class="d-flex align-start mr-4">
                            <v-icon x-large color="primary" style="margin-top: 6px;">
                                {{ tableIcon }}
                            </v-icon>
                        </div>
                        <div class="d-flex align-center">
                            <span class="text-h6 font-weight-regular"
                                style="white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                            >
                                {{ tableName }}
                            </span>
                        </div>
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col class="d-flex justify-start">
                        <v-text-field
                            v-model="search"
                            class="mb-6 mr-4"
                            append-icon="mdi-magnify"
                            label="Pesquisar"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-col>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" class="mb-2 mr-4 pa-0"
                                v-on=" on "
                                min-width="48px"
                                @click="getWorkflows()"
                                :disabled="!event"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Atualizar</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn color="primary" class="mb-2 mr-2 pa-0"
                                v-on="{ ...tooltip }"
                                min-width="48px"
                                @click="$router.push('/')"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>

                </v-row>

                <v-divider class="mt-1 mb-2"></v-divider>

                <v-layout>
                    <v-flex xs4>
                        <v-select
                            v-model="event"
                            :items="events"
                            @input="getWorkflows()"
                            class="mx-4 my-1"
                            item-text="description"
                            item-value="id"               
                            label="Evento"
                            persistent-hint
                        ></v-select>
                    </v-flex>
                </v-layout>

                <!-- TAMBÉM AS COLUNAS DE DESCRIÇÃO DO NÍVEL E JUSTIFY -->
                
                <v-dialog v-model="dialog" max-width="800" max-height="450" style="z-index: 1000;">
                    <v-card style="position: relative;">
                        <v-toolbar
                            dark
                            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                            style="position: sticky; top: 0; width: 100%; z-index: 5;" 
                            elevation="0"
                        >
                            <v-toolbar-title> {{ planning_group }} </v-toolbar-title>
                            <v-spacer />

                            <v-tooltip bottom>
                                <template v-slot:activator="on">
                                    <v-btn color="transparent" class="mb-2 mt-2"
                                        v-on="on"
                                        min-width="48px"
                                        @click="dialog = false"
                                        depressed
                                    >
                                        <v-icon class="white--text">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </v-toolbar>
                        <v-container>
                            <v-layout justify-end wrap>
                                <v-flex xs12>
                                    <v-select
                                        v-model="event"
                                        :items="events"
                                        class="mx-4 my-1"
                                        item-text="description"
                                        item-value="id"               
                                        label="Evento"
                                        persistent-hint
                                        readonly
                                    ></v-select>
                                </v-flex>
                                <v-flex>
                                    <v-data-table
                                        :headers="headersLevels"
                                        :items="levels"
                                        :search="search"
                                        :loading="loading"
                                        item-key="id_level"
                                        sort-by="Nome"
                                        class="elevation-0"
                                    >
                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.id_status="{ item }">
                                            <v-layout>
                                                <v-icon
                                                    class="ml-4"
                                                    :color="item.id_status === 4 ? 'green' : 'red'"
                                                >
                                                    mdi-circle
                                                </v-icon>
                                            </v-layout>
                                        </template>
                                    </v-data-table>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card>
                </v-dialog>

                <v-data-table
                    :headers="headers"
                    :items="workflows"
                    :search="search"
                    :loading="loading"
                    item-key="id_approval_flow_level"
                    sort-by="planning_group_description"
                    class="elevation-1"
                >
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.color="{ item }">
                        <v-layout>
                            <v-icon
                                class="ml-4"
                                :color="item.color"
                            >
                                mdi-circle
                            </v-icon>
                        </v-layout>
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.date="{ item }">
                        <v-layout>
                            {{item.date}}
                            <v-spacer></v-spacer>
                            <v-icon
                                class="mr-8"
                                @click="planning_group = item.planning_group_description, dialog = true, approvalListLevels(item.id_planning_group)"
                            >
                                mdi-folder-open
                            </v-icon>
                        </v-layout>
                    </template>
                </v-data-table>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name:'EventStatusPlanningGroup',

    computed: {
        endpoint() {
            return [
                this.$ipApprovalFlow,
                'event-status-planning-group',
                {
                    conditions: []
                }
            ]
        },
    },

    data: () => ({
        tableName: 'Consulta do status do fluxo de aprovação do evento',
        tableIcon: 'mdi-table-large',
        tableDescription: 'Forneça uma descrição para esta tabela.',
        tableRows: 100,

        dialog: false,
        
        loading: false,
        search: '',

        workflows: [],
        planning_group: '',
        events: [],
        event: null,
        headers: [
            {
                text: 'Semáforo', value: 'color'
            },
            {
                text: 'Grupo de Planejamento', value: 'planning_group_description'
            },
            {
                text: 'Status', value: 'status'
            },
            {
                text: 'Usuário', value: 'name'
            },
            {
                text: 'Data', value: 'date'
            }
        ],

        levels: [],
        headersLevels: [
            {
                text: 'Semáforo', value: 'id_status'
            },
            {
                text: 'Níveis', value: 'id_level'
            },
            {
                text: 'Descrição do nível', value: 'approval_flow_level_description'
            },
            {
                text: 'Usuário', value: 'name'
            },
            {
                text: 'Data', value: 'date'
            },
            {
                text: 'Justificativa', value: 'justification'
            }
        ]

    }),

    created () {
        this.getEvents()
    },

    methods: {

        async getEvents () {
            const payload = {
                filter: {
                    orderColumn: "description",
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_module',
                            operator: '=',
                            value: 6
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipEvent + 'event/list-options', {...payload})
                if (res) {
                    this.events = res.data.rows
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async getWorkflows() {
            this.loading = true

            if (!this.event) {
                return this.loading = false
            }
            
            const payload = {
                id_event: this.event
            }
            
            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/list-general', { ...payload })
                if (res) {
                    this.workflows = res.data.rows
                    this.workflows.forEach((workflow) => {
                        workflow.date ? workflow.date = moment(workflow.date).format('DD/MM/YYYY HH:mm:ss') : false
                    })
                }

                this.loading = false
            } catch (err) {
                this.$fnError(err)
                this.loading = false
            }
        },

        async approvalListLevels (id) {
            const payload = {
                id_event: this.event,
                id_planning_group: id
            }
            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/list-levels', { ...payload })
                if (res) {
                    this.levels = res.data.rows
                    this.levels.forEach((level) => {
                        level.date ? level.date = moment(level.date).format('DD/MM/YYYY HH:mm:ss') : false
                    })
                }
            } catch (err) {
                this.$fnError(err)
            }
        }
    }
}
</script>

<style>
</style>